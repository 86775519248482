import { bech32 } from 'bech32';

/**
 * 十六进制地址转bech32地址
 */
export const hexToBech32 = (hexAddress: string, prefix = 'fb') => {
  hexAddress = hexAddress.trim()
  if (hexAddress.slice(0, 2) === '0x') {
    hexAddress = hexAddress.slice(2);
  }
  let words = bech32.toWords(Buffer.from(hexAddress, 'hex'));
  return bech32.encode(prefix, words);
}


/**
 * bech32地址转十六进制地址
 */
export const bech32ToHex = (bech32Address: string) => {
  bech32Address = bech32Address.trim();
  const { words } = bech32.decode(bech32Address);
  return "0x" + Buffer.from(bech32.fromWords(words)).toString('hex');
}

